<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      class="d-flex flex-column"
      style="flex: 1"
    >
      <relationship-form
        :custom-entity="customEntity"
        :visible="visible"
        :selected-item="selectedItem"
        :items="items"
        :entities="entities"
        @saved="add"
        @updated="update"
        @close="onClose"
      />
      <div class="form-sidebar__options ">
        <div class="p-1 form-sidebar__options-header">
          <h5>{{ $t("Relationships") }}</h5>
        </div>
        <div style="overflow-y: auto; max-height: 200px;">
          <div
            v-if="!displayItems.length"
            class="p-1"
          >
            {{ $t('No relationships yet') }}
          </div>
          <template v-for="(item, index) of displayItems">
            <relationship-item
              :key="item.key"
              :resource="item"
              :mode="mode"
              @edit="edit"
              @remove="remove(item, index)"
            />
          </template>
        </div>
      </div>
      <template v-if="mode !== 'view'">
        <span class="field-hint mb-1">
          {{ $t('Documents and Notes will automatically included upon saving this entity.') }}
        </span>
        <div class="d-flex justify-content-end mb-1">
          <b-button
            variant="primary"
            @click="visible = true"
          >
            {{ $t('Add Relationship') }}
          </b-button>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { toPerspective } from '@/helpers/customEntity'
import customEntityService from '@/services/customEntity'
import RootEvents from '@/constants/rootEvents'
import RelationshipForm from './Form.vue'
import RelationshipItem from './Item.vue'

export default {
  components: {
    RelationshipForm,
    RelationshipItem,
  },
  props: {
    mode: {
      type: String,
      default: null,
    },
    resource: {
      type: Array,
      default: null,
    },
    customEntity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      items: [],
      selectedItem: null,
      loading: false,
      entities: [],
    }
  },
  computed: {
    displayItems() {
      return toPerspective(this.customEntity.key, this.items).value.map(item => {
        if (this.customEntity.version) {
          item.label = item.label2
        }

        return item
      })
    },
  },
  watch: {
    visible(value) {
      this.$emit('isFormOpen', value)

      if (this.visible) {
        this.getInitialData()
      }
    },
    resource: {
      handler(value) {
        if (!value) return
        this.items = value
        this.$emit('update', this.items)
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on(RootEvents.CLEAR_RELATIONSHIPS, () => {
      this.items = []
    })

    this.$root.$on(RootEvents.GET_LINKABLE_ENTITIES, () => this.getInitialData())

    this.getInitialData()
  },
  destroyed() {
    this.$root.$off(RootEvents.CLEAR_RELATIONSHIPS)
    this.$root.$off(RootEvents.GET_LINKABLE_ENTITIES)
  },
  methods: {
    async getInitialData() {
      this.loading = true
      const { response } = await this.$async(customEntityService.getEntities())
      this.entities = response.data.entities
        .filter(item => item.key !== this.customEntity.key)
      this.loading = false
    },
    update(value) {
      const index = this.items.findIndex(item => item.uuid === value.uuid)
      this.$set(this.items, index, value)
      this.selectedItem = null
      this.$emit('update', this.items)
    },
    edit(value) {
      this.selectedItem = value
      this.visible = true
    },
    remove(item, index) {
      if (item.id) {
        this.$swal({
          title: this.$t('Warning'),
          text: this.$t('Are you sure you want to remove existing relationship?'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            customEntityService.removeRelationship({
              entity1: {
                schema: item.schema1,
                entityKey: item.key1,
              },
              entity2: {
                schema: item.schema2,
                entityKey: item.key2,
              },
              relationshipId: item.id,
            }).then(() => {
              this.$root.$emit(RootEvents.GET_RELATIONSHIPS) // prevents optimistic lock exception / fetch latest custom entity version
              this.$root.$emit(RootEvents.GET_CUSTOM_ENTITIES)
            })
          }
        })
      } else {
        this.$delete(this.items, index)
      }
    },
    add(item) {
      this.items.push({
        uuid: this.$uuidv4(),
        ...item,
      })
      this.$emit('update', this.items)
    },
    onClose() {
      this.selectedItem = null
      this.visible = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
</style>
