<template>
  <div>
    <b-sidebar
      id="form-sidebar"
      :visible="visible"
      bg-variant="white"
      no-close-on-backdrop
      no-close-on-esc
      no-header
      right
      backdrop
      shadow
    >
      <div class="form-sidebar d-flex flex-column">
        <div class="form-sidebar__header d-flex justify-content-between align-items-center p-1">
          <div class="d-flex align-items-center">
            <h1 class="form-sidebar__header-title m-0">
              {{ $t('Custom Entity Details') }}
            </h1>
          </div>
          <button
            class="btn btn-icon rounded-circle btn-close"
            @click="close"
          >
            <feather-icon
              icon="XIcon"
              style="color: $colour--white"
            />
          </button>
        </div>
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          rounded="sm"
          variant="transparent"
          class="d-flex flex-column"
          style="flex: 1"
        >
          <div
            class="p-2"
            style="overflow-y: auto; height: calc(100vh - 111px)"
          >
            <div>
              <span class="text-sm">{{ $t('Entity Name') }}</span>
              <p class="font-weight-bold">
                {{ resource.label }}
              </p>
            </div>
            <div>
              <span class="text-sm">{{ $t('Entity Key') }}</span>
              <p class="font-weight-bold">
                {{ resource.key }}
              </p>
            </div>
            <relationship-list
              class="mt-2"
              mode="view"
              :custom-entity="resource"
              :resource="resource.relationships"
            />
          </div>
        </b-overlay>
        <div
          spinner-variant="primary"
          rounded="sm"
          variant="transparent"
          class="d-flex flex-column"
        >
          <div class="form-sidebar__footer d-flex justify-content-start align-items-center p-1">
            <b-button variant="outline-primary" @click="close">
              {{ $t('Close') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import customEntityService from '@/services/customEntity'
import RootEvents from '@/constants/rootEvents'
import RelationshipList from './Relationships/List.vue'

export default {
  components: {
    RelationshipList,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      resource: {},
    }
  },
  watch: {
    visible: {
      handler(visible) {
        if (!visible) return this.reset()
        this.getOne()
      },
      immediate: true,
    },
  },
  methods: {
    async getOne() {
      this.loading = true
      const { response } = await this.$async(customEntityService.findOne(this.resourceId))
      this.resource = response.data
      this.loading = false
    },
    close() {
      this.$emit('close')
    },
    reset() {
      this.resource = {}
      this.loading = false
      this.$root.$emit(RootEvents.CLEAR_CUSTOM_FIELDS) // clear custom fields inside child component
      this.$root.$emit(RootEvents.CLEAR_RELATIONSHIPS)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.text-sm {
  font-size: 0.9rem;
  color: $colour--text-muted;
}
</style>
