<template>
  <b-card
    no-body
    class="system-settings-card"
  >
    <div>
      <sidebar-form
        :visible="showForm"
        :resource-id="resourceId"
        @saved="refreshData()"
        @close="onCloseForm"
        @field-form-close="onFieldFormClose"
      />
      <custom-entity-view
        :visible="showView"
        :resource-id="resourceId"
        @close="showView = false"
      />
      <div class="m-2 d-flex justify-content-between table-actions">
        <b-button
          id="create-button"
          variant="primary"
          :class="{
            'icon-disabled': !hasSystemSettingsActions,
          }"
          @click="hasSystemSettingsActions && create()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('New Entity') }}</span>
        </b-button>
        <b-tooltip target="create-button">
          {{ hasSystemSettingsActions ? $t('Create') : $t('You dont have permission to create') }}
        </b-tooltip>
      </div>
      <div class="m-2">
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(label)="{ item }">
            <span style="white-space: nowrap">
              {{ item.label }}
            </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.key}-preview-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !hasSystemSettingsActions,
                }"
                @click="edit(item.key)"
              />
              <b-tooltip
                :title="hasSystemSettingsActions ? $t('Edit') : $t('You dont have permission to update')"
                :target="`edit-row-${item.key}-preview-icon`"
                placement="left"
              />
              <feather-icon
                :id="`delete-row-${item.id}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !hasSystemSettingsActions,
                }"
                @click="hasSystemSettingsActions && remove(item)"
              />
              <b-tooltip
                :title="hasSystemSettingsActions ? $t('Remove') : $t('You dont have permission to remove')"
                :target="`delete-row-${item.id}-preview-icon`"
                placement="left"
              />
              <feather-icon
                :id="`view-row-${item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="view(item.key)"
              />
              <b-tooltip
                :title="$t('View')"
                :target="`view-row-${item.id}-preview-icon`"
                placement="left"
              />
            </div>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { customFieldTypeIcons } from '@/constants/customFieldTypes'
import listMixinFactory from '@/mixins/list'
import customEntityService from '@/services/customEntity'
import RootEvents from '@/constants/rootEvents'
import { mapActions } from 'vuex'
import SidebarForm from './Form.vue'
import CustomEntityView from './View.vue'

export default {
  components: {
    SidebarForm,
    CustomEntityView,
  },
  mixins: [
    listMixinFactory({
      routeName: 'custom-entity-settings',
      service: customEntityService,
      tableColumns: [
        { key: 'label', label: 'Name', sortable: true },
        { key: 'key', label: 'Key', class: 'disabled' },
        {
          key: 'numberOfFields',
          label: 'No. Fields',
          class: 'text-center disabled',
        },
        {
          key: 'numberOfRelatedTables',
          label: 'No. Relationships',
          class: 'text-center disabled',
        },
        {
          key: 'numberOfRecords',
          label: 'No. Records',
          class: 'text-center disabled',
        },
        { key: 'actions', class: 'text-center disabled' },
      ],
      searchField: 'label',
      sort: {
        label: 'label',
      },
    }),
  ],
  data() {
    return {
      customFieldTypeIcons,
      resourceId: null,
      showForm: false,
      showView: false,
      showHistory: false,
    }
  },
  mounted() {
    this.$root.$on(RootEvents.GET_CUSTOM_ENTITIES, () => this.fetchList())
  },
  destroyed() {
    this.$root.$off(RootEvents.GET_CUSTOM_ENTITIES)
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      removeCustomEntity: 'user/removeCustomEntity',
    }),
    view(id) {
      this.showView = true
      this.resourceId = id
    },
    async refreshData() {
      this.fetchList()
      this.$root.$emit(RootEvents.GET_LINKABLE_ENTITIES)
      this.getUserInfo()
    },
    onFieldFormClose() {
      this.showForm = true
    },
    displayPermissions(permissions) {
      return this.$lodash.groupBy(permissions, 'name')
    },
    create() {
      this.resourceId = null
      this.showForm = true
    },
    edit(id) {
      if (!this.hasSystemSettingsActions) return
      this.resourceId = id
      this.showForm = true
    },
    remove({ key, version }) {
      if (!this.hasSystemSettingsActions) return
      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to delete?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          // await this.deleteResource(key, version)
          this.service.destroy(key, { version }).then(() => {
            this.removeCustomEntity(key)
            this.fetchList()
          })
        }
      })
    },
    onCloseForm() {
      this.resourceId = null
      this.showForm = false
      this.showHistory = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
