import * as yup from 'yup'

const keyPattern = /^[a-z_0-9\\]*$/
export const customEntitySchema = ({ $t }) => yup.object().shape({
  label: yup.string().required().max(20, `${$t('Max')} 20`),
  key: yup.string().required()
    .min(3, `${$t('Min')} 3`)
    .max(20, `${$t('Max')} 20`)
    .matches(keyPattern, 'Invalid Characters'),
})
