import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/custom_schema/table'

export default {
  ...apiHelper(API_URL),
  getOne: (id, query) => API.get(`${API_URL}/${id}/info?${toQuery(query)}`),
  findOne: (id, query) => API.get(`${API_URL}/${id}?${toQuery(query)}`),
  autocomplete: search => API.get(`${API_URL}/autocomplete?query=${search}`),
  getEntities(payload) {
    return API.get(`/api/custom_schema/linkable_entities?${toQuery(payload)}`)
  },
  update: (id, payload) => {
    const data = {
      version: payload.version,
      label: payload.label,
      newColumns: payload.columns.filter(item => !item.id),
      updatedColumns: payload.columns.filter(item => item.id && !item.deleted),
      deletedColumns: payload.columns.filter(item => item.deleted).map(item => item.id),
      visible: payload.visible,
    }
    return API.put(`${API_URL}/${id}`, data)
  },
  removeRelationship(payload) {
    return API.put('/api/custom_schema/remove_relationship', payload)
  },
  addRelationship(payload) {
    return API.put('/api/custom_schema/add_relationship', payload)
  },
}
