<template>
  <div
    class="p-1 custom-entity__item"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span
          class="font-weight-bold text-gray d-block tw-text-muted"
        >
          {{ $t(loadType(resource.type)) }}
        </span>
        <span class="font-weight-bold">
          {{ resource.label }}
        </span>
      </div>
      <div
        class="d-flex justify-content-end"
        style="width: 43px;"
      >
        <feather-icon
          v-if="!resource.id"
          id="create-relationship-button"
          icon="EditIcon"
          size="16"
          class="cursor-pointer mr-1"
          :class="{
            'icon-disabled': resource.id
          }"
          @click="!resource.id && edit(resource)"
        />
        <feather-icon
          v-if="mode !== 'view'"
          icon="TrashIcon"
          size="16"
          class="cursor-pointer"
          @click="$emit('remove')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RelationshipTypes } from '@/constants/relationshipTypes'

export default {
  props: {
    mode: {
      type: String,
      default: null,
    },
    resource: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    loadType(type) {
      return Object.keys(RelationshipTypes)
        .find(key => RelationshipTypes[key] === type)
    },
    edit(value) {
      this.$emit('edit', value)
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.custom-entity__item {
  border-bottom: 1px solid var(--colour--sidebar-form-divider);
}
</style>
