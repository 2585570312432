<template>
  <div
    tabindex="0"
    @keyup.esc="$emit('close')"
  >
    <b-sidebar
      id="form-sidebar"
      :visible="visible"
      bg-variant="white"
      no-close-on-backdrop
      no-close-on-esc
      no-header
      right
      backdrop
      shadow
    >
      <div class="form-sidebar d-flex flex-column">
        <div class="form-sidebar__header d-flex justify-content-between align-items-center p-1">
          <div class="d-flex flex-column">
            <div
              class="d-flex mb-1 cursor-pointer"
              @click="close"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              <p class="m-0">
                {{ $t('Back') }}
              </p>
            </div>
            <h1 class="form-sidebar__header-title m-0 text-uppercase">
              {{ $t(formTitle) }}
            </h1>
          </div>
        </div>
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          rounded="sm"
          variant="transparent"
          class="d-flex flex-column"
          style="flex: 1"
        >
          <form
            id="relationshipForm"
            ref="form"
            autocomplete="off"
            class="p-2"
            style="flex: 1"
            @submit.prevent
          >
            <prozess-field-wrapper
              icon="CrosshairIcon"
              :error="$hasError('targetEntity')"
            >
              <prozess-select
                v-model="resource.targetEntity"
                style="flex: 1"
                :placeholder="$t('Target Entity')"
                :options="entities"
                label="label"
                :reduce="item => ({
                  label: item.label,
                  schema: item.schema,
                  entityKey: item.key || item.entityKey
                })"
              />
            </prozess-field-wrapper>
            <prozess-input
              v-model="resource.parentRelationshipName"
              class="mt-3"
              :placeholder="$t('This entity\'s relationship name')"
              icon="TypeIcon"
              field="parentRelationshipName"
              name="parentRelationshipName"
              :error="$hasError('parentRelationshipName')"
              @enter="save"
            />
            <prozess-field-wrapper
              icon="RepeatIcon"
              :error="$hasError('thisEntityHas')"
            >
              <prozess-select
                v-model="resource.thisEntityHas"
                style="flex: 1"
                :placeholder="$t('This entity has')"
                :options="types"
                label="label"
                :reduce="item => item.value"
              />
            </prozess-field-wrapper>
            <prozess-input
              v-model="resource.childRelationshipName"
              class="mt-3"
              :placeholder="$t('Target entity\'s relationship name')"
              icon="TypeIcon"
              field="childRelationshipName"
              name="childRelationshipName"
              :error="$hasError('childRelationshipName')"
              @enter="save"
            />
            <prozess-field-wrapper
              icon="RepeatIcon"
              :error="$hasError('targetEntityHas')"
            >
              <prozess-select
                v-model="resource.targetEntityHas"
                style="flex: 1"
                :placeholder="$t('Target entity has')"
                :options="types"
                label="label"
                :reduce="item => item.value"
              />
            </prozess-field-wrapper>
            <b-form-checkbox
              v-model="resource.favorite"
              switch
              inline
              @keyup.enter.native="save"
            >
              <span style="font-size: 1rem">{{ $t('Favorite') }}</span>
            </b-form-checkbox>
          </form>
          <div
            class="form-sidebar__footer d-flex justify-content-around align-items-center p-1"
          >
            <button
              class="btn btn-primary mr-1 d-flex justify-content-center align-items-center"
              type="submit"
              :disabled="saving"
              @click="save"
            >
              <b-spinner
                v-if="saving"
                small
                class="mr-1"
              />
              <feather-icon
                v-else
                :icon="saveIcon"
                class="mr-50"
              />
              <span>{{ $t(saveLabel) }}</span>
            </button>
            <b-button variant="outline-primary" class="mr-1" @click="close">
              {{ $t('Close') }}
            </b-button>
          </div>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { relationshipSchema } from '@/schema/relationship'
import { RelationshipTypes } from '@/constants/relationshipTypes'
import { Schema } from '@/constants/app'
import RootEvents from '@/constants/rootEvents'
import customEntityService from '@/services/customEntity'

export default {
  props: {
    customEntity: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Array,
      default: () => [],
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      formIcon: 'PlusIcon',
      resource: {},
      options: [],
      saving: false,
      loading: true,
      errors: [],
    }
  },
  computed: {
    types() {
      return ['one', 'many'].map(item => ({
        label: this.$t(item),
        value: item,
      }))
    },
    saveIcon() {
      return this.resourceId ? '' : 'SaveIcon'
    },
    saveLabel() {
      return this.resourceId ? 'Save Changes' : 'Save'
    },
    formTitle() {
      return this.selectedItem ? 'Edit Relationship On Entity' : 'Add Relationship To Entity'
    },
  },
  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          if (this.selectedItem) {
            this.resource = {
              ...this.$lodash.cloneDeep(this.selectedItem),
              targetEntity: this.selectedItem.connectedEntity,
              ...this.loadType(this.selectedItem.type),
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadType(type) {
      const [thisEntityHas, targetEntityHas] = Object.keys(RelationshipTypes)
        .find(key => RelationshipTypes[key] === type)
        .split(' to ')

      return { thisEntityHas, targetEntityHas }
    },
    handleGroupInput() {
      this.$forceUpdate()
    },
    reset() {
      this.errors = []
      this.resource = {}
      this.loading = false
      this.saving = false
    },
    close() {
      this.$emit('close')
    },
    async save() {
      this.errors = await this.yupValidate(
        relationshipSchema(),
        this.resource,
      )
      if (this.errors.length > 0) return

      const {
        targetEntity, thisEntityHas, targetEntityHas, favorite,
        parentRelationshipName, childRelationshipName,
      } = this.resource

      const relationshipAlreadyExist = this.items
        .filter(item => {
          if (this.selectedItem && this.selectedItem.uuid === item.id) return false
          return true
        })
        .find(item => {
          const key = this.customEntity.key === item.key2 ? 'key1' : 'key2'
          const entityKey = this.$optional(item, 'connectedEntity.entityKey', null) || item[key]
          return targetEntity.entityKey === entityKey
        })

      if (relationshipAlreadyExist) {
        return this.showWarning('Entity already added.')
      }

      const type = RelationshipTypes[`${thisEntityHas} to ${targetEntityHas}`]

      targetEntity.childRelationshipName = childRelationshipName

      if (this.customEntity.version) {
        this.saving = true
        await this.$async(customEntityService.addRelationship({
          entity1: {
            schema: Schema.CUSTOM,
            entityKey: this.customEntity.key,
            parentRelationshipName,
          },
          type,
          favorite,
          entity2: targetEntity,
        }))

        this.saving = true
        this.close()
        this.$root.$emit(RootEvents.GET_RELATIONSHIPS)
        this.$root.$emit(RootEvents.GET_CUSTOM_ENTITIES)
      } else {
        this.close()
        return this.$emit(this.selectedItem ? 'updated' : 'saved', {
          ...this.selectedItem,
          label: targetEntity.label,
          type,
          favorite,
          childRelationshipName,
          parentRelationshipName,
          connectedEntity: targetEntity,
        })
      }
    },
  }, // methods
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
</style>
