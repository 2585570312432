<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    form-icon="PlusIcon"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      id="customEntityForm"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-input
        v-model="resource.label"
        :placeholder="$t('Name')"
        icon="TypeIcon"
        field="label"
        name="label"
        :error="$hasError('label')"
        @enter="save"
      />
      <prozess-input
        v-model="resource.key"
        :disabled="!!resourceId"
        :placeholder="$t('Key')"
        icon="KeyIcon"
        field="key"
        :error="$hasError('key')"
        @input="handleKeyInput"
        @paste="preventSpecialChars"
        @keypress="preventSpecialChars"
        @enter="save"
      />
      <!-- Relationship CRUD-->
      <relationship-list
        v-if="!loading"
        :custom-entity="resource"
        :resource="resourceId ? resource.relationships : null"
        @update="updateRelationships"
      />
      <div class="text-right">
        <b-form-checkbox v-model="resource.visible" class="mt-2" switch inline>
          <span style="font-size: 1rem">{{ $t('Visible to users') }}</span>
        </b-form-checkbox>
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { customEntitySchema } from '@/schema/customEntity'
import { preventSpecialChars } from '@/helpers/app'
import customEntityService from '@/services/customEntity'
import prozessInput from '@/@core/components/ProzessInput.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'
import RootEvents from '@/constants/rootEvents'
import RelationshipList from './Relationships/List.vue'

const defaultCustomEntity = {
  label: '',
  key: '',
  columns: [],
  relationships: [],
  visible: true,
}

export default {
  components: {
    prozessInput,
    RelationshipList,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      resource: defaultCustomEntity,
      options: [],
      saving: false,
      loading: true,
      errors: [],
      keyFieldTouched: false,
      customFields: [],
      relationships: [],
    }
  },
  computed: {
    formTitle() {
      return this.resourceId ? 'Edit Custom Entity' : 'New Custom Entity'
    },
  },
  watch: {
    'resource.label': function (value) {
      if (this.resourceId) return

      if (this.keyFieldTouched === false) {
        const replaceSpecialChar = value?.replace(/[^\w\s]/gi, '') ?? ''
        this.resource.key = this.$case.snake(replaceSpecialChar)
      }
    },
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          if (this.resourceId) this.getOne()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on(RootEvents.GET_RELATIONSHIPS, () => {
      customEntityService.findOne(this.resourceId).then(res => {
        this.resource.version = res.data.version
        this.resource.relationships = res.data.relationships
      })
    })
  },
  destroyed() {
    this.$root.$off(RootEvents.GET_RELATIONSHIPS)
  },
  methods: {
    updateRelationships(items) {
      this.relationships = items
    },
    updateCustomFields(items) {
      this.customFields = items
    },

    preventSpecialChars(e) {
      return preventSpecialChars(e, {
        callback: data => {
          this.resource.key = data
          this.$forceUpdate()
        },

        regExp: '^[a-z0-9_]+$',
      })
    },

    handleKeyInput() {
      this.keyFieldTouched = true
    },
    handleGroupInput() {
      this.$forceUpdate()
    },
    reset() {
      this.$root.$emit(RootEvents.CLEAR_CUSTOM_FIELDS) // clear custom fields inside child component
      this.$root.$emit(RootEvents.CLEAR_RELATIONSHIPS)
      this.customFields = []
      this.relationships = []
      this.errors = []
      this.resource = this.$lodash.cloneDeep(defaultCustomEntity)
      this.loading = false
      this.saving = false
      this.keyFieldTouched = false
    },
    getOne() {
      this.loading = true
      customEntityService
        .findOne(this.resourceId)
        .then(res => {
          this.resource = {
            ...res.data,
            columns: res.data.columns.map(item => ({
              ...item,
              uuid: this.$uuidv4(),
            })),
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close')
    },
    async save() {
      this.resource.label = this.resource.label.trim()

      const resource = {
        ...this.resource,
        columns: this.customFields.map(item => {
          item = {
            ...item,
            type: item.fieldType,
          }

          if (item.type !== CustomFieldTypes.List) {
            delete item.values
          }

          return item
        }),
        relationships: this.relationships,
      }

      this.errors = await this.yupValidate(
        customEntitySchema({
          $t: value => this.$t(value),
        }),
        resource,
      )
      if (this.errors.length > 0) return

      Object.keys(this.resource).forEach(key => {
        if (
          this.resource[key] !== null
          && this.resource[key].toString().trim() === ''
        ) {
          delete this.resource[key]
        }
      })

      this.saving = true

      const { error } = await this.$async(this.createOrUpdate(resource))

      if (error) {
        this.errors = this.$lodash.get(error, 'response.data.errors', [])
      } else {
        this.$emit('saved')
        this.close()
      }

      this.$getUserAndPermissions()

      this.saving = false
    },
    createOrUpdate(data) {
      return this.resourceId
        ? customEntityService.update(this.resourceId, data)
        : customEntityService.store(data)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
</style>
